import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyGetSpecificBookingByIdQuery } from "../../services/booking";
import testDateSeries from "../../utils/dateSeries";
import { getOnlyDate, getOnlyTimeHHMM } from "../../utils/formatDateTime";
import moment from "moment";
import Loader from "../../helpers/Loader";

const OnlineBookingDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { _id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  const [fetchApi] = useLazyGetSpecificBookingByIdQuery();
  const { state } = location;

  const fetchDataById = async (_id: any) => {
    setIsLoading(true);
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      setIsLoading(false);
      console.log("9090", response);
      if (response.statusCode === 200) {
        setByIdDetails(response?.data || []);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Booking Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-bookings");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h2" className="subhdng">
                        Customer Details
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Customer Name</Typography>
                      <Typography>
                        {" "}
                        {ByIdDetails?.parents
                          ? ByIdDetails.parents?.name
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography>
                        {" "}
                        {ByIdDetails?.parents
                          ? ByIdDetails.parents?.email
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Phone Number</Typography>
                      <Typography>
                        {" "}
                        {ByIdDetails?.parents
                          ? ByIdDetails.parents?.phoneNo
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h2" className="subhdng">
                        Tutor Details
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">
                        Service Provider Name
                      </Typography>
                      <Typography>
                        {" "}
                        {ByIdDetails?.tutors ? ByIdDetails.tutors?.name : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography>
                        {" "}
                        {ByIdDetails?.tutors
                          ? ByIdDetails.tutors?.email
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Phone Number</Typography>
                      <Typography>
                        {" "}
                        {ByIdDetails?.tutors?.dialCode ? ByIdDetails?.tutors?.dialCode : ""} {ByIdDetails?.tutors?.phoneNo
                          ? ByIdDetails.tutors?.phoneNo
                          : "N/A"}

                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h2" className="subhdng">
                        Booking Details
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Subject </Typography>
                      <Typography component="p">
                        {ByIdDetails?.subjects &&
                          ByIdDetails.subjects.length > 0 ? (
                          <ul>
                            {ByIdDetails.subjects.map(
                              (subject: any, index: number) => (
                                <li key={index}>{subject.name}</li>
                              )
                            )}
                          </ul>
                        ) : (
                          "N/A"
                        )}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Hours</Typography>
                      <Typography> {ByIdDetails?.totalNoOfHours}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Date & Time Slot</Typography>

                      {testDateSeries(ByIdDetails?.bookingdetails) ? (
                        <div>
                          <p>
                            from :{" "}
                            {moment(ByIdDetails.bookingdetails[0].date).format("DD-MM-YYYY")}{" "}
                          </p>
                          <p>
                            to :{" "}
                            {moment(
                              ByIdDetails.bookingdetails[
                                ByIdDetails.bookingdetails.length - 1
                              ].date
                            ).format("DD-MM-YYYY")}{" "}
                          </p>
                          <p>Time : </p>
                          <p>
                            {moment(
                              ByIdDetails.bookingdetails[0].startTime
                            ).format("LT")}{" "}
                            -{" "}
                            {moment(
                              ByIdDetails.bookingdetails[0].endTime
                            ).format("LT")}{" "}
                          </p>
                        </div>
                      ) : (
                        ByIdDetails?.bookingdetails?.map(
                          (detail: any, detailIndex: number) => (
                            <div key={detailIndex}>
                              <p style={{ margin: 0 }}>
                                Date : {moment(detail.date).format("DD-MM-YYYY")}
                              </p>
                              <p style={{ margin: 0, marginBottom: "10px" }}>
                                Time : {moment(detail.startTime).format("LT")}-
                                {moment(detail.endTime).format("LT")}
                              </p>
                              <p></p>
                            </div>
                          )
                        )
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Booking Id</Typography>
                      <Typography component="p"> {ByIdDetails?._id}</Typography>
                    </Box>
                  </Grid>
                  {ByIdDetails?.cancelledAt || ByIdDetails?.cancelReason ? (
                    <>
                      <Grid item xs={12}>
                        <Box>
                          <Typography component="h2" className="subhdng">
                            Cancelation Details
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography component="h5">
                            Cancelation Date
                          </Typography>
                          <Typography component="p">
                            {moment(ByIdDetails?.cancelledAt).format(
                              "YYYY-MM-DD, h:mm:ss a"
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography component="h5">
                            Reason for cancelation
                          </Typography>
                          <Typography component="p">
                            {" "}
                            {ByIdDetails?.cancelReason}{" "}
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}

                  {state === "ONGOING" ? (
                    <>
                      {" "}
                      {/* <Grid item xs={12}>
                        <Typography
                          sx={{
                            padding: "20px 0 0",
                            fontSize: "20px",
                            color: "#1d1d1d",
                            fontWeight: "700",
                          }}
                          component="h2"
                        >
                          Accept/Reject Booking
                        </Typography>
                      </Grid> */}
                      {/* <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Accept
                        </Button>
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Reject
                        </Button>
                      </Grid> */}
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default OnlineBookingDetails;
