// development
export const API_URL = "https://tutorapi.appgrowthcompany.com/api/v1/Admin/";
export const media_url = "https://tutorapi.appgrowthcompany.com/api/v1/Upload/";
// staging
// export const API_URL =
//   "https://stagetutorapi.appgrowthcompany.com/api/v1/Admin";
// export const media_url =
//   "https://stagetutorapi.appgrowthcompany.com/api/v1/Upload/";

// // api live link
// export const API_URL = "https://api.tutorhail.com/api/v1/Admin";
// export const media_url = "https://api.tutorhail.com/api/v1/Upload/";

export const GOOGLE_API_KEY = "AIzaSyAtmkzL_1Y9LLgOXknagjGh_-TZUbE3U_A";
export const END_POINTS = {
  login: "login",
  mediaUpload: "uploadFile",
  get_user: "getProfile",
  logout: "logout",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "resetPassword",
  changePassword: "changePassword",
  //dummy cmt

  ////profile
  updateProfile: "updateProfile",
  getProfile: "getProfile",
  //cms
  addCms: "addCms",
  getCms: "getCms",
  ///tutor
  tutorReview: "tutorReview",
  addTutor: "addTutor",
  getTutor: "getTutor",
  updateTutor: "updateTutor",
  deleteTutor: "deleteTutor",
  getTutorDetails: "getTutorDetails",
  getTeachingDetails: "getTeachingDetails",
  teachingDetails: "teachingDetails",
  tutorExport: "tutorExport",
  tutorUpdate: "tutorUpdate",
  currTutor: "currTutor",
  ///bank
  addBankDetails: "addBankDetails",
  getBankDetails: "getBankDetails",
  updateBankDetails: "updateBankDetails",
  //USERS
  getParent: "getParent",
  updateParent: "updateParent",
  deleteParent: "deleteParent",
  parentExport: "parentExport",
  parentBooking: "parentBooking",
  //documentation
  addDocuments: "addDocuments",
  updateDocuments: "updateDocuments",
  deleteDocuments: "deleteDocuments",
  getDocuments: "getDocuments",
  ///bookings
  export: "export",
  booking: "booking",
  refundBookingAmount: "refundPayment",
  refundList: "refundList",
  //customerSupport
  customersupport: "customersupport",
  deleteCustomerSupport: "customersupport",
  revertQuery: "revertQuery",
  //review
  review: "review",
  deleteReview: "deleteReview",

  //dashboard
  userType: "userType",
  propertyType: "propertyType",
  dashboard: "dashboard",
  earningGraph: "earningGraph",
  userGraph: "userGraph",
  tutorGraph: "tutorGraph",
  bookingGraph: "bookingGraph",
  //settings
  settings: "setting",
  activityHistory: "activityHistory",
  notification: "notification",
  tutorEarning: "tutorEarning",
  withdrawStatus: "withdrawStatus",
  invoice: "invoice",
  tutorPayment: "tutorPayment",
};
