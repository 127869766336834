 
 import { AllFaq, CommonBody, Faqs } from "../types/General";
 import emptySplitApi from "../utils/rtk";
 
 import { END_POINTS } from "../helpers";
 
 
 type CommonResponseType = {
  statusCode: number;
   message: string;
 };
 
 type postAddFaq = {
   question: string;
   answer: string;
 };
 
 type getAllFaq = {
   refundPolicy: string;
   eula:string;
   cancellationPolicy?: string;
   termsAndConditions?: string;
   contactSupport: any;
   aboutUs: string;
   email: string;
   legal: string;
   phone_no: string;
   privacyPolicy: string;
   country_code: string;
   faq(faq: any): unknown;
   data: AllFaq;
 };
 
 type deleteFaq = {
   data: string;
 };
 
 export const Managefaq = emptySplitApi.injectEndpoints({
   endpoints: (builder) => ({
     addFaq: builder.mutation<
       CommonResponseType & { data: postAddFaq },
       CommonBody
     >({
       query: (body) => ({
         url: END_POINTS.addCms,
         method: "POST",
         body,
       }),
     }),
 
     getAllFaq: builder.query<CommonResponseType & { data: getAllFaq }, {}>({
       query: () => ({
         url: END_POINTS.getCms,
         method: "GET",
       }),
     }),
 
    //  deleteFaq: builder.query<
    //    CommonResponseType & { data: Faqs },
    //    { id: number }
    //  >({
    //    query: ({ id }) => ({
    //      url: `${END_POINTS.delete_faq}/${id}/`,
    //      method: "DELETE",
    //    }),
    //  }),
   }),
 });
 
 export const {
    useLazyGetAllFaqQuery,
    useAddFaqMutation
 } = Managefaq;
 