import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Box, Button, Card, Tab, Tabs, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BookingsList } from "../../features";
import { errorToast, successToast } from "../../helpers";
import { useLazyBookingCsvQuery } from "../../services/booking";
import OngoingBookingsList from "../../features/bookingDetails";
import CompletedBookingsList from "../../features/bookingDetails/completed";
import UpcomingBookingsList from "../../features/bookingDetails/upcoming";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CancelledBookingListing from "../../features/bookingDetails/cancelledBooking";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageBookings = () => {
  const [exportCsv] = useLazyBookingCsvQuery();
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { state } = useLocation();
  const bookingTypes:any = {
    0: 6,
    1: 3,
    2: 7,
    3: 5
  };

  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({
        isOnline:false,
        bookingType:bookingTypes[value] !== undefined ? bookingTypes[value] : ""

      }).unwrap();
      console.log("eugded", res);

      const parsedRes = typeof res === "string" ? JSON.parse(res) : res;

      if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
        window.open(parsedRes.message.s3Location);
        successToast("csv exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  useEffect(() => {
    if (state) {
      setValue(Number(state))
    }
  }, [])
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Bookings</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                {/* <Tab label="Completed Bookings" {...a11yProps(0)} />
                <Tab label="Cancelled Bookings" {...a11yProps(1)} />
                <Tab label="Ongoing/Upcoming Bookings" {...a11yProps(2)} /> */}

                <Tab label="On-Going Bookings" {...a11yProps(0)} />
                <Tab label="completed Bookings" {...a11yProps(1)} />
                <Tab label="Upcoming Bookings" {...a11yProps(2)} />
                <Tab label="Cancelled Bookings" {...a11yProps(3)} />
              </Tabs>
              <Button className="btn btn_primary" onClick={handleExportCsv}>
                <FileDownloadIcon />
                Export CSV
              </Button>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <OngoingBookingsList value={value} online={false} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <CompletedBookingsList value={value} online={false} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <UpcomingBookingsList value={value} online={false} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <CancelledBookingListing value={value} online={false} />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageBookings;
