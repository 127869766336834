import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
import Faq from "./faq";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import CustomerSupport from "./customerSupport";
import ManagePaymentRequest from "./paymentRequest.tsx";
import PaymentRequestDetails from "./paymentRequest.tsx/details";
import RecievedNotifications from "./manageNotification/receivedNotification";
import ManageServiceProvider from "./manageTutors";
import AddServiceProvider from "./manageTutors/add";
 import ManagePayment from "./managePayment";
 import PaymentDetails from "./managePayment/details";
import ChangePassword from "./auth/changePassword";
import ServiceProviderDetails from "./manageTutors/details";
import RefundRequestDetails from "./mangeRefundRequest/details";
import ManageBookings from "./manageBookings";
import BookingDetails from "./manageBookings/details";
import ManageRefundRequest from "./mangeRefundRequest";
import ManageReviews from "./manageReviews";
import ReviewDetails from "./manageReviews/details";
import NextTutorDetail from "./manageTutors/details/nextTutor";
import ManageTutor from "./manageTutors";
import AddTutor from "./manageTutors/add";
import TutorDetail from "./manageTutors/details";
import Settings from "./Settings/indesx";
import ManageOnlineBookings from "./manageOnlineBookings";
import OnlineBookingDetails from "./manageOnlineBookings/details";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  ManageUsers,
  ForgotPasswordPage,
  VerifyOtp,
  Analytics,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  ManageNotifications,
  AddNotification,
  CustomerSupport,
 
  RecievedNotifications,
  ManageServiceProvider,
  AddServiceProvider,
 
  ChangePassword,
  ServiceProviderDetails,
 
  ManageBookings,
  BookingDetails,
  
  ManageReviews,
  ReviewDetails,
  

  //new
  ManageTutor,
  AddTutor,
  TutorDetail,
  ManagePayment,
  PaymentDetails,
  Settings,
  ManagePaymentRequest,
  PaymentRequestDetails,
  NextTutorDetail,
  RefundRequestDetails,
  ManageRefundRequest,
  ManageOnlineBookings,
  OnlineBookingDetails
};
