import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, FormControl, Grid, NativeSelect, Paper } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import SchoolIcon from "@mui/icons-material/School";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InventoryIcon from "@mui/icons-material/Inventory";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LineChart from "../../components/LineChart";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PaidIcon from "@mui/icons-material/Paid";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import PanoramaIcon from "@mui/icons-material/Panorama";
import { useNavigate } from "react-router-dom";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  useLazyGetEarningQuery,
  useLazyGetDashboArdApiQuery,
  useLazyGetGraphUserGraphQuery,
  useLazyExportUserCsvQuery,
  useLazyExportTutorCsvQuery,
  useLazyExportBookingCsvQuery,
  useLazyExportRevenueCsvQuery,
  useLazyGetTheBookingGraphQuery,
  useLazyGetGraphTutorGraphQuery,
} from "../../services/dashboard";
import { errorToast, successToast } from "../../helpers";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const [exportUserCsv] = useLazyExportUserCsvQuery();
  const [exportTutorCsv] = useLazyExportTutorCsvQuery();
  const [exportBookingCsv] = useLazyExportBookingCsvQuery();
  const [exportRevenue] = useLazyExportRevenueCsvQuery();
  const [selectedType1, setSelectedType1] = useState<string>("weekly");
  //  const [selectedType2, setSelectedType2] = useState<string>("weekly");
  const [selectedType3, setSelectedType3] = useState<string>("weekly");
  const [selectedType4, setSelectedType4] = useState<string>("weekly");
  const [selectedType5, setSelectedType5] = useState<string>("weekly");

  const [bookingGraph, setbookingGraph] = useState<any>([]);
  const [tutorGraph, settutorGraph] = useState<any>([]);

  const [TutorGraph] = useLazyGetGraphTutorGraphQuery();
  const [BookingsGraph] = useLazyGetTheBookingGraphQuery();
  const handleExportUserCsv = async () => {
    try {
      const res = await exportUserCsv({
        type: selectedType1,
        query: "csv",
      }).unwrap();
      console.log("eugded", res);

      const parsedRes = typeof res === "string" ? JSON.parse(res) : res;

      if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
        window.open(parsedRes.message.s3Location);
        successToast("CSV exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };
  const handleExportTutorCsv = async () => {
    try {
      const res = await exportTutorCsv({
        type: selectedType2,
        query: "csv",
      }).unwrap();
      console.log("eugded", res);

      const parsedRes = typeof res === "string" ? JSON.parse(res) : res;

      if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
        window.open(parsedRes.message.s3Location);
        successToast("CSV exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };
  const handleExportBookingCsv = async () => {
    try {
      const res = await exportBookingCsv({
        type: selectedType3,
        query: "csv",
      }).unwrap();
      console.log("eugded", res);

      const parsedRes = typeof res === "string" ? JSON.parse(res) : res;

      if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
        window.open(parsedRes.message.s3Location);
        successToast("CSV exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };

  const handleExportRevenueCsv = async () => {
    try {
      const res = await exportRevenue({
        type: selectedType,
        query: "csv",
      }).unwrap();
      console.log("eugded", res);

      const parsedRes = typeof res === "string" ? JSON.parse(res) : res;

      if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
        successToast("CSV exported successfully");
        window.open(parsedRes.message.s3Location);
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };

  const [user, serUser] = useState<any>([]);
  const [selectedType2, setSelectedType2] = useState<string>("weekly");
  const fetchUsersGraph = async (type: string) => {
    try {
      const response = await Usergraph(type).unwrap();
      console.log("res---  analysis", response);
      const formattedData = response?.data?.map((item: any) => ({
        label: item.name,
        value: item.value,
      }));
      serUser(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    fetchUsersGraph(selectedType2);
  }, [selectedType2]);

  const graphUsersData = {
    labels: user?.map((item: any) => item.label),
    datasets: [
      {
        label: "Total Users",
        data: user?.map((item: any) => item.value),
        borderColor: "#05a633",
        backgroundColor: "#05a633",
      },
    ],
  };

  const fetchBookingGraph = async (type: string) => {
    try {
      const response = await BookingsGraph(type).unwrap();
      console.log("res---  analysis", response);
      const formattedData = response?.data?.map((item: any) => ({
        label: item.name,
        value: item.value,
      }));
      setbookingGraph(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const fetchTutorsGraph = async (type: string) => {
    try {
      const response = await TutorGraph(type).unwrap();
      console.log("res---  analysis", response);
      const formattedData = response?.data?.map((item: any) => ({
        label: item.name,
        value: item.value,
      }));
      settutorGraph(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const handleChange1 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType1(newType);
  };
  const handleChange2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType2(newType);
  };
  const handleChange3 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType3(newType);
  };
  const handleChange4 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType4(newType);
  };
  const handleChange5 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType5(newType);
  };
  useEffect(() => {
    fetchEarningGraph(selectedType4);
  }, [selectedType4]);
  useEffect(() => {
    fetchBookingGraph(selectedType3);
  }, [selectedType3]);
  useEffect(() => {
    fetchUsersGraph(selectedType1);
  }, [selectedType1]);
  useEffect(() => {
    fetchTutorsGraph(selectedType2);
  }, [selectedType2]);
  const [data, setData] = useState<any>();
  const [earningGraph, setEarningGraph] = useState<any>([]);
  const [EarningGraph] = useLazyGetEarningQuery();

  const [selectedType, setSelectedType] = useState<string>("weekly");
  const fetchEarningGraph = async (type: string) => {
    try {
      const response = await EarningGraph(type).unwrap();
      console.log("res---Earning analysis", response);
      const formattedData = response?.data.map((item: any) => ({
        label: item.name,
        value: item.value,
      }));
      setEarningGraph(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const [Usergraph] = useLazyGetGraphUserGraphQuery();

  const [dashboard] = useLazyGetDashboArdApiQuery();
  const fetchDashBoard = async () => {
    try {
      const response = await dashboard({ type: selectedType5 }).unwrap();
      if (response.statusCode === 200) {
        setData(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const graphAdvertisersData = {
    labels: tutorGraph?.map((item: any) => item.label),
    datasets: [
      {
        label: "Total Tutors",
        data: tutorGraph?.map((item: any) => item.value),
        borderColor: "#05a633",
        backgroundColor: "#05a633",
      },
    ],
  };

  const graphTransactionsData = {
    labels: earningGraph?.map((item: any) => item.label),
    datasets: [
      {
        label: "Total Revenue",
        data: earningGraph?.map((item: any) => item.value),
        borderColor: "#05a633",
        backgroundColor: "#05a633",
      },
    ],
  };
  useEffect(() => {
    fetchDashBoard();
  }, [selectedType5]);
  useEffect(() => {
    fetchEarningGraph(selectedType); // Fetch initial data based on selected type
  }, [selectedType]);



  const graphRevenueData = {
    labels: bookingGraph?.map((item: any) => item.label),
    datasets: [
      {
        label: "Total Bookings",
        data: bookingGraph?.map((item: any) => item.value),
        borderColor: "#05a633",
        backgroundColor: "#05a633",
      },
    ],
  };
  function formatRevenue(labelValue: number) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
          : Math.abs(Number(labelValue));
  }

  const navigate = useNavigate();

  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">Dashboard</h1>
        <FormControl>
          <NativeSelect
            defaultValue={selectedType5}
            onChange={handleChange5}
            inputProps={{
              name: "report",
              id: "uncontrolled-native",
            }}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </NativeSelect>
        </FormControl>
      </div>

      <Grid container spacing={2} className="dashGrid">

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-tutors", { state: 0 })}
          >
            <SchoolIcon className="svg_icn" />
            <div>
              <h3>Total Tutors</h3>
              <h4 className="mn_hdng">{data?.totalTutors}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-tutors", { state: 0 })}
          >
            <SchoolIcon className="svg_icn" />
            <div>
              <h3>Accepted Tutors</h3>
              <h4 className="mn_hdng">{data?.acceptedTutors}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-tutors", { state: 2 })}
          >
            <SchoolIcon className="svg_icn" />
            <div>
              <h3>Rejected Tutors</h3>
              <h4 className="mn_hdng">{data?.rejectedTutors}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-tutors", { state: 3 })}
          >
            <SchoolIcon className="svg_icn" />
            <div>
              <h3>Deleted Tutors</h3>
              <h4 className="mn_hdng">{data?.deletedTutors}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers", { state: 0 })}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Users</h3>
              <h4 className="mn_hdng">{data?.totalUsers}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers", { state: 1 })}
          >
            <SchoolIcon className="svg_icn" />
            <div>
              <h3>Deleted Users</h3>
              <h4 className="mn_hdng">{data?.deletedUsers}</h4>
            </div>
          </Item>
        </Grid>


        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-bookings")}
          >
            <FormatListBulletedIcon className="svg_icn" />
            <div>
              <h3>Total Bookings</h3>
              <h4 className="mn_hdng">{data?.totalBookings}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-bookings", { state: 2 })}
          >
            <FiberNewIcon className="svg_icn" />
            <div>
              <h3>New Bookings</h3>
              <h4 className="mn_hdng">{data?.newBookings}</h4>
            </div>
          </Item>
        </Grid>


        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-bookings", { state: 0 })}
          >
            <HourglassTopIcon className="svg_icn" />
            <div>
              <h3>Ongoing Bookings</h3>
              <h4 className="mn_hdng">
                {data?.ongoingBookings
                  ? data?.ongoingBookings
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-bookings", { state: 2 })}
          >
            <PendingActionsIcon className="svg_icn" />
            <div>
              <h3>Pending Bookings</h3>
              <h4 className="mn_hdng">
                {data?.pendingBookings
                  ? data?.pendingBookings
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-bookings", { state: 0 })}
          >
            <ThumbUpIcon className="svg_icn" />
            <div>
              <h3>Approved Bookings</h3>
              <h4 className="mn_hdng">
                {data?.acceptedBookings
                  ? data?.acceptedBookings
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-bookings", { state: 3 })}
          >
            <ThumbDownIcon className="svg_icn" />
            <div>
              <h3>Rejected Bookings</h3>
              <h4 className="mn_hdng">
                {data?.rejectedBookings
                  ? data?.rejectedBookings
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-bookings", { state: 1 })}
          >
            <CheckCircleIcon className="svg_icn" />
            <div>
              <h3>Completed Bookings</h3>
              <h4 className="mn_hdng">
                {data?.completedBookings
                  ? data?.completedBookings
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
          >
            <CheckCircleIcon className="svg_icn" />
            <div>
              <h3>Total number of Hours</h3>
              <h4 className="mn_hdng">
                {data?.totalNoOfHours
                  ? data?.totalNoOfHours
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/payment")}
          >
            <AttachMoneyIcon className="svg_icn" />
            <div>
              <h3>Total Revenue</h3>
              <h4 className="mn_hdng">
                {data?.totalRevenue
                  ? formatRevenue(data?.totalRevenue)
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/payment")}
          >
            <AttachMoneyIcon className="svg_icn" />
            <div>
              <h3>Daily Revenue</h3>
              <h4 className="mn_hdng">
                {data?.todayRevenue
                  ? formatRevenue(data?.todayRevenue)
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>

      </Grid>


      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Users
              <FormControl>
                <div>
                  <Button
                    sx={{ margin: "5px" }}
                    className="btn btn_primary"
                    onClick={handleExportUserCsv}
                  >
                    <FileDownloadIcon /> Export CSV
                  </Button>
                  <NativeSelect
                    defaultValue={selectedType1}
                    onChange={handleChange1}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </NativeSelect>
                </div>
              </FormControl>
            </h2>
            <LineChart data={graphUsersData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Tutors
              <FormControl>
                <div>
                  <Button
                    sx={{ margin: "5px" }}
                    className="btn btn_primary"
                    onClick={handleExportTutorCsv}
                  >
                    <FileDownloadIcon /> Export CSV
                  </Button>
                  <NativeSelect
                    defaultValue={selectedType2}
                    onChange={handleChange2}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </NativeSelect>
                </div>
              </FormControl>
            </h2>
            <LineChart data={graphAdvertisersData} />
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Bookings
              <FormControl>
                <div>
                  <Button
                    sx={{ margin: "5px" }}
                    className="btn btn_primary"
                    onClick={handleExportBookingCsv}
                  >
                    <FileDownloadIcon /> Export CSV
                  </Button>
                  <NativeSelect
                    defaultValue={selectedType3}
                    onChange={handleChange3}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </NativeSelect>
                </div>
              </FormControl>
            </h2>
            <LineChart data={graphRevenueData} />
          </div>
        </Grid>
        {/* <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">Total Downloads</h2>
            <LineChart data={graphDownloadsData} />
          </Item>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default DashBoard;
