import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useFormik } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import { UploadMedia, Uploadpdf } from "../../utils/uploadMedia";
import { errorToast, getFromStorage, STORAGE_KEYS, successToast } from "../../helpers";
import {
  useLazyDeleteDocsByIdQuery,
  useLazyGetIdProofByIdQuery,
  usePostaddDocumentsMutation,
  usePutUpdateDocsMutation,
} from "../../services/documentation";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EmbedPDF } from "@simplepdf/react-embed-pdf";
import moment from "moment";

const Achievements = () => {
  const [items, setItems] = useState<any[]>([]);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [editableCard, setEditableCard] = useState<any>();
  const [AchievementsDetails, setAchievementsDetails] = useState<any[]>([]);
  const [videoTitle, setVideoTitle] = useState<string>("");
  const [ongoing, setOngoing] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState(false);
  const [editCard, setEditCard] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [items2, setItems2] = useState<any[]>([]);
  const [deleteDocById] = useLazyDeleteDocsByIdQuery();
  const [profile_picture, setProfilePicture] = useState<any>("");
  const [adddocUMENT] = usePostaddDocumentsMutation();
  const [tutorId, setTutorId] = useState<string | null>(null);
  const location = useLocation();
  const { state } = location;
  const tId = getFromStorage(STORAGE_KEYS.tutorId)
  const { _id } = useParams();
  const [imageId, setImageId] = useState(null);
  const [getIdProofById] = useLazyGetIdProofByIdQuery();
  const [certificationError, setCertificationError] = useState<boolean>(false);
  const [updateDocsApi] = usePutUpdateDocsMutation();
  const targetRef1: any = useRef(null);
  const targetRef2: any = useRef(null);
  const validationSchema = Yup.object({
    startDate: Yup.date().required("Start date is required"),
    endDate: !ongoing ? Yup.date().required("End date is required") : Yup.date().optional(),
    description: Yup.string().required("Institution Name is required"),
  });

  const handleImageClick = (imageUrl: React.SetStateAction<string>) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setSelectedImage("");
  };

  const handleAddClick = async () => {
    if (!profile_picture) {
      setError(true);
      return;
    }

    const payload = {
      tutorId: _id ? _id : tutorId,
      documents: [
        {
          frontImage: profile_picture || "",
          description: formik1.values.description,
          documentName: 1,
          documentType: 2,
          startDate: formik1.values.startDate,
          endDate: ongoing && !formik1.values.endDate ? "" : formik1.values.endDate,
          onGoing: ongoing
        },
      ],
    };
    const payloadForEdit = {
      frontImage: profile_picture || "",
      description: formik1.values.description,
      documentName: 1,
      documentType: 2,
      startDate: formik1.values.startDate,
      endDate: ongoing && !formik1.values.endDate ? "" : formik1.values.endDate,
      onGoing: ongoing
    };
    console.log(payload, "body");

    if (formik1?.values?.startDate > formik1?.values?.endDate && !ongoing) {
      errorToast("End Date has to be greater than start Date");
      return;
    }

    if (editCard) {
      try {
        const encryptedBody = generateEncryptedKeyBody(
          payloadForEdit
        ) as CommonBody;
        const response = await updateDocsApi({
          id: editableCard?._id,
          body: encryptedBody,
        }).unwrap();
        if (response.statusCode === 200) {
          successToast("Experience Docs updated successfully");
          scrollToElement(targetRef2.current.offsetTop - 100);
          setEditableCard(null);
          setEditCard(false);
          formik1.setFieldValue("startDate", "");
          formik1.setFieldValue("endDate", "");
          formik1.setFieldValue("description", "");
          setProfilePicture("");
          setOngoing(false)
        }
        if (_id) {
          fetchDataById(_id);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "Something went wrong");
      }
    } else {
      try {
        const encryptedBody = generateEncryptedKeyBody(payload) as CommonBody;
        const response = await adddocUMENT(encryptedBody).unwrap();
        if (response.statusCode === 200) {
          successToast("Experience Docs added successfully");
          scrollToElement(targetRef2.current.offsetTop - 100);
          if (_id) {
            fetchDataById(_id);
          }
          // const newItem = {
          //   _id: items2.length.toString(),
          //   title: videoTitle,
          //   description: formik1.values.description,
          // };
          const newItem =  {
            frontImage: profile_picture || "",
            description: formik1.values.description,
            documentName: 1,
            documentType: 2,
            startDate: formik1.values.startDate,
            endDate: ongoing && !formik1.values.endDate ? "" : formik1.values.endDate,
            onGoing: ongoing
  
          }
          setItems([newItem, ...items]);
          setAchievementsDetails(prev=>([...prev,newItem]))
          setProfilePicture(null);
          setVideoTitle("");
          // setDescription("");
          formik1?.setFieldValue("startDate", "");
          formik1?.setFieldValue("endDate", "");
          formik1?.setFieldValue("description", "");
          setOngoing(false)
          setCertificationError(false);
          setError(false);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "Something went wrong");
      }
    }
  };

  const fetchDataById = async (tutorId: any, documentType?: number) => {
    try {
      const response = await getIdProofById({
        userId: tutorId,
        documentType: 2,
      }).unwrap();
      if (response.statusCode === 200) {
        setAchievementsDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log(editableCard, "editableCard");

  useEffect(() => {
    if (_id || tId) {

      fetchDataById(_id ? (_id !== "undefined" ? _id : tId) : tId);
    }
  }, [_id, tId]);

  const handleDeleteClick = async (_id: string) => {
    try {
      const response = await deleteDocById({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        const updatedItems = items.filter((item: any) => item._id !== _id);
        setItems(updatedItems);

        setAchievementsDetails((prevDetails: any) =>
          prevDetails.filter((item: any) => item._id !== _id)
        );

        const deletedItem = AchievementsDetails.find(
          (item: any) => item._id === _id
        );
        if (deletedItem && deletedItem.frontImage) {
          setProfilePicture((prevProfilePicture: any) => {
            if (prevProfilePicture === deletedItem.frontImage) {
              return null;
            }
            return prevProfilePicture;
          });
        }

        successToast("Experience docs deleted successfully");
      } else {
        errorToast("Failed to delete experience docs. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting achievement:", error);
      errorToast("Failed to delete achievement. Please try again.");
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        if (type === "profile_picture") {
          setProfilePicture(null);
        }
        return;
      }

      let res;
      if (file.type === "application/pdf") {
        res = await Uploadpdf(file);
        if (res?.statusCode === 200) {
          if (type === "profile_picture") {
            setProfilePicture(res?.data?.image); // Assuming pdfUrl is the URL of the uploaded PDF
          }
        } else {
          errorToast(res?.message);
        }
      } else {
        res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          if (type === "profile_picture") {
            setProfilePicture(res?.data?.image); // Assuming image is the URL of the uploaded image
          }
        } else {
          errorToast(res?.message);
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      errorToast("Error uploading file. Please try again.");
    }
  };
  const formik1 = useFormik({
    initialValues: {
      startDate: editableCard?.startDate
        ? moment(editableCard?.startDate).format("YYYY-MM-DD")
        : "",
      endDate: editableCard?.endDate
        ? moment(editableCard?.endDate).format("YYYY-MM-DD")
        : "",
      description: editableCard?.description ? editableCard?.description : "",
    },
    validationSchema: validationSchema,
    onSubmit: handleAddClick,
  });

  console.log(AchievementsDetails, "AchievementsDetails");

  useEffect(() => {
    const storedTutorId = localStorage.getItem("tutorId");
    if (storedTutorId) {
      setTutorId(storedTutorId);
    }
  }, []);

  const scrollToElement = (target: any) => {
    window.scrollTo({
      top: target,
      behavior: "smooth",
    });
  };
  return (
    <div className="main_layout">
      <div className="dashboard">
        {state?.type === "edit" ? (
          <h1 className="mn_hdng">Edit Tutor</h1>
        ) : (
          <h1 className="mn_hdng">Experience</h1>
        )}
        <Button
          className="btn btn_primary"
          onClick={() => {
            navigate("/manage-tutors");
          }}
        >
          Back
        </Button>
      </div>
      <Card className="cards">
        <CardContent>
          <div className="card_main" ref={targetRef2}>
            {AchievementsDetails &&
              AchievementsDetails.map((item: any) => (
                <div key={item._id} className="card">
                  <>
                    <div
                      style={{
                        position: "absolute",
                        right: "22px",
                        padding: "5px",
                      }}
                    >
                      {_id ? (
                       <>
                          <Tooltip title="Edit">
                            <EditIcon
                              onClick={() => {
                                setEditCard(true);
                                setEditableCard(item);
                                formik1.setFieldValue(
                                  "description",
                                  item?.description
                                );
                                formik1.setFieldValue(
                                  "startDate",
                                  moment(item?.startDate).format("YYYY-MM")
                                );
                                formik1.setFieldValue(
                                  "endDate",
                                  moment(item?.endDate).format("YYYY-MM")
                                );
                                setProfilePicture(item?.frontImage);
                                scrollToElement(targetRef1.current.offsetTop - 100);
                              }}
                              sx={{ margin: "5px" }}
  
                            />
                          </Tooltip>
                           <Tooltip title="Delete">
                           <DeleteIcon
                             onClick={() => handleDeleteClick(item._id)}
                             sx={{ margin: "5px" }}
                           />
                         </Tooltip>
                       </>
                      ) : ("")}


                     
                    </div>
                  </>
                  {item.frontImage && item?.frontImage?.endsWith(".pdf") ? (
                    <EmbedPDF companyIdentifier="yourcompany">
                      <a href={`${item.frontImage}`}>
                        <CardMedia
                          component="img"
                          image="/static/images/check.png"
                          alt="photo"
                          sx={{ width: 200, height: 200 }}
                        />
                      </a>
                    </EmbedPDF>
                  ) : (
                    <CardMedia
                      component="img"
                      image={item.frontImage}
                      alt="photo"
                      sx={{ width: "100%", height: 250 }}
                      onClick={() => handleImageClick(item.frontImage)}
                    />
                  )}
                  <div className="desc">
                    <h4>{item?.description}</h4>
                    <h4>Start Date: {moment(item?.startDate).format("MMMM YYYY")}</h4>
                    {item?.endDate ? <h4>End Date: {moment(item?.endDate).format("MMMM YYYY")}</h4> : ""}
                    {item?.onGoing ? <h4>Status: Ongoing</h4> : ""}
                    <h4>Institutional Name:{item?.description}</h4>
                  </div>
                </div>
              ))}
          </div>

          <Grid container spacing={2} ref={targetRef1}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography
                className="custom_label"
                variant="h5"
                sx={{ width: "100%" }}
              >
                Institution Name
              </Typography>
              <TextField
                hiddenLabel
                type={"text"}
                name="description"
                variant="outlined"
                fullWidth
                placeholder="Enter institution name"
                id="description"
                className="text_field"
                onChange={formik1?.handleChange}
                onBlur={formik1.handleBlur}
                value={formik1?.values?.description}
                error={formik1.touched.description && Boolean(formik1.errors.description)}
                helperText={
                  formik1.touched.description && typeof formik1.errors.description === 'string'
                    ? formik1.errors.description
                    : ''
                }
              />

              <div
                style={{
                  flexDirection: "row",
                  paddingTop: 20,
                  marginRight: 6,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Start Date</Typography>
                  <TextField
                    hiddenLabel
                    type={"month"}
                    name="startDate"
                    variant="outlined"
                    fullWidth
                    placeholder="Start Date"
                    id="startDate"
                    className="text_field"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={formik1.values.startDate}
                    helperText={formik1.touched.startDate && formik1.errors.startDate}
                    error={formik1.touched.startDate && Boolean(formik1.errors.startDate)}

                  />

                </Grid>

                <Grid className="pos_rel_on" item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">End Date</Typography>
                  <TextField
                    hiddenLabel
                    type={"month"}
                    name="endDate"
                    variant="outlined"
                    fullWidth
                    placeholder="End Date"
                    id="endDate"
                    className="text_field"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={formik1.values.endDate}
                    error={formik1.touched.endDate && Boolean(formik1.errors.endDate)}
                    helperText={formik1.touched.endDate && formik1.errors.endDate}
                  />
                  <span className="pos_abs_on" >
                    <Checkbox
                      checked={ongoing}
                      onChange={() => setOngoing(!ongoing)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography className="custom_label">Ongoing</Typography>
                  </span>


                </Grid>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}></Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Upload documents</Typography>


              {profile_picture ? (
                profile_picture?.endsWith(".pdf") ? (
                  <EmbedPDF companyIdentifier="yourcompany">
                    <a href={`${profile_picture}`}>
                      <div className="upload_image_preview2">
                        <CardMedia
                          component="img"
                          image="/static/images/check.png"
                          alt="photo"
                          sx={{ width: 200, height: 200 }}
                        />
                        <CancelIcon
                          onClick={() => setProfilePicture(null)}
                          color="error"
                        />
                      </div>
                    </a>
                  </EmbedPDF>
                ) : (
                  <div className="upload_image_preview2">
                    <CardMedia
                      component="img"
                      image={profile_picture}
                      alt="photo"
                      sx={{ width: 200, height: 200 }}
                    />
                    <CancelIcon
                      onClick={() => setProfilePicture(null)}
                      color="error"
                    />
                  </div>
                )
              ) : (
                <Box className="upload_image_bnr">
                  <label htmlFor="icon-button-file-vdo">
                    <Input
                      sx={{ display: "none" }}
                      id="icon-button-file-vdo"
                      type="file"
                      inputProps={{
                        accept: "image/png,image/jpeg,application/pdf",
                      }}
                      onChange={(e: any) =>
                        handleImageUpload(e, "profile_picture")
                      }
                    />
                    <Button component="span" className="upload_image_btn">
                      <AddIcon />
                    </Button>
                  </label>
                </Box>
              )}

              {error && !profile_picture && (
                <h6 className="err_msg">Image is required.</h6>
              )}
            </Grid>
            <div style={{ marginTop: 20 }} />
          </Grid>

          <Grid item lg={8} md={8} sm={8} xs={8} />

          <Grid item lg={8} md={8} sm={8} style={{ paddingTop: "8px" }}>
            {items?.length < 100 ? (
              <div className="form_btn">
                <Button
                  size="large"
                  type="button"
                  className="btn btn_primary"
                  onClick={() => {
                    formik1.handleSubmit();

                  }}
                >
                  {editCard ? "Save" : "Add"}
                </Button>
              </div>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={openModal} onClose={closeModal} maxWidth="xl" fullWidth>
        <DialogTitle>Zoomed Image</DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <img
              src={selectedImage}
              alt="Zoomed Image"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Achievements;
