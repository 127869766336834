import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Card, Box, Button, Tabs, Tab, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import Accepted from "../../features/tutor/Accepted";
import Rejected from "../../features/tutor/Rejected";
import Pending from "../../features/tutor/Pending";
import {
  useLazyDeleteTutorByIdQuery,
  useLazyGetAllTutorListingQuery,
} from "../../services/tutor";
import { errorToast, successToast } from "../../helpers";
import { useLazyTutorCsvQuery } from "../../services/tutor";
import Achievements from "./Achievements";
import Cerrifications from "./Cerrifications";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Documentation = () => {
 
  const [value, setValue] = useState<number>(0);
 

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="main_loyout">
      {/* <div className="dashboard">
          <h1 className="mn_hdng">Documentations</h1>
        </div> */}
      <Card className="cards">
        {/* <Box className="custom_tabs"> */}
        <Box className="flx_sc">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            className="custom_tabs_links"
          >
            <Tab label="Experience" {...a11yProps(0)} />
            <Tab label="Education Background" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Achievements />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Cerrifications />
        </CustomTabPanel>

        {/* </Box> */}
      </Card>
    </div>
  );
};

export default Documentation;
