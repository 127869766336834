import React, { useEffect, useState } from "react";

import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate, useParams } from "react-router-dom";
import { isValidInput } from "../../utils/validation";
import {
  useLazyBookingCsvQuery,
  useLazyGetAllBookingsQuery,
  usePostRefundBookingAmountMutation,
} from "../../services/booking";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import Loader from "../../helpers/Loader";
import testDateSeries from "../../utils/dateSeries";

import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

import RefundModal from "../../components/modals/RefundModal";
import moment from "moment";

type props = {
  value: number;
  online: boolean;
};
const formatDate = (dateString: string | number | Date) => {
  if (!dateString) return "-";
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
    timeZoneName: "short",
  };

  return date.toLocaleString(undefined, options);
};

const getOnlyDate = (dateString: string | number | Date) => {
  if (!dateString) return "-";
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  };

  return date.toLocaleDateString(undefined, options);
};

const getOnlyTimeHHMM = (timeString: string | number | Date) => {
  if (!timeString) return "-";
  const date = new Date(timeString);

  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  };

  return date.toLocaleTimeString(undefined, options);
};

const UpcomingBookingsList = ({ value, online }: props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  const [rows, setRows] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [fetchBooking] = useLazyGetAllBookingsQuery();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  let totalPages = Math.ceil(totalCount / 10);

  const fetchData = async (page: number, search: string) => {
    setIsLoading(true);
    try {
      const response = await fetchBooking({
        page,
        search,
        bookingType: 7,
        isOnline: online
      }).unwrap();
      setIsLoading(false);
      if (response.statusCode === 200) {
        setRows(response?.data?.booking);
        setTotalCount(response?.data?.totalBooking);
      } else {
        setRows([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm]);

  return (
    <div>
      <Loader isLoad={isLoading} />
      <Box className="cards_header">
        <SearchBar
          value={searchTerm}
          searchTerm={searchTerm}
          onCross={() => setSearchTerm("")}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
      </Box>

      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Booking No</TableCell>
              <TableCell align="center">Customer Details</TableCell>
              <TableCell align="center">Tutor Details</TableCell>
              <TableCell align="center">Service Details</TableCell>
              <TableCell align="center">Start Date / Time</TableCell>
              <TableCell align="center">End Date / Time</TableCell>
              {/* <TableCell align="center">Refund Request</TableCell> */}
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows?.map((row: any, i: any) => (
              <TableRow key={row?.name}>
                {/* Render table cells with booking data */}
                <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                <TableCell align="center">{row?.bookingNumber}</TableCell>
                <TableCell>
                  <p style={{ margin: 0 }}>{row?.parents?.name}</p>
                  <p style={{ margin: 0 }}>{row?.parents?.email}</p>
                </TableCell>
                <TableCell>
                  <p style={{ margin: 0 }}>{row?.tutors?.name}</p>
                  <p style={{ margin: 0 }}>{row?.tutors?.email}</p>
                </TableCell>
                <TableCell>
                  {row?.subjectspecializations?.map(
                    (subject: any, index: number) => (
                      <p key={index} style={{ margin: 0 }}>
                        {subject?.name}
                      </p>
                    )
                  )}
                </TableCell>

                <TableCell>
                  {testDateSeries(row.bookingdetails) ? (
                    <div>
                      <p>from : {moment(row.bookingdetails[0].date).format("DD-MM-YYYY")}</p>
                      <p>
                        to :{" "}
                        {moment(row.bookingdetails[row.bookingdetails.length - 1].date).format("DD-MM-YYYY")}
                        {" "}
                      </p>
                      <p>Time : </p>
                      <p>
                        {moment(row.bookingdetails[0].startTime).format("LT")}
                      </p>
                    </div>
                  ) : (
                    row?.bookingdetails?.map(
                      (detail: any, detailIndex: number) => (
                        <div key={detailIndex}>
                          <p style={{ margin: 0 }}>
                            Date :  {moment(detail.date).format("DD-MM-YYYY")}
                          </p>
                          <p style={{ margin: 0 }}>
                            Time : {moment(detail.startTime).format("LT")}
                          </p>
                          <p></p>
                        </div>
                      )
                    )
                  )}
                </TableCell>
                <TableCell>
                  {testDateSeries(row.bookingdetails) ? (
                    <div>
                      <p>from : {moment(row.bookingdetails[0].date).format("DD-MM-YYYY")} </p>
                      <p>
                        to :{" "}
                        {moment(row.bookingdetails[row.bookingdetails.length - 1].date).format("DD-MM-YYYY")}
                        {" "}
                      </p>
                      <p>Time : </p>
                      <p>
                        {moment(row.bookingdetails[0].endTime).format("LT")}
                      </p>
                    </div>
                  ) : (
                    row.bookingdetails.map(
                      (detail: any, detailIndex: number) => (
                        <div key={detailIndex}>
                          <p style={{ margin: 0 }}>
                            Date : {moment(detail.date).format("DD-MM-YYYY")}
                          </p>
                          <p style={{ margin: 0 }}>
                            Time :
                            {moment(row.bookingdetails[0].endTime).format("LT")}
                          </p>
                          <p></p>
                        </div>
                      )
                    )
                  )}
                </TableCell>

                <TableCell>
                  <Box className="table_actions">
                    <IconButton
                      onClick={() =>
                        navigate(`/manage-bookings/details/${row?._id}`, {
                          state:{from:online ?"ONLINE":""}
                        })
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      {/* <RefundModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleAcceptRefund(selectedId)}
        name="refund"
      /> */}
    </div>
  );
};

export default UpcomingBookingsList;
