import React, { useState, useEffect } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import { useLazyGetAllFaqQuery, useAddFaqMutation } from "../../services/manageFaq";
import { errorToast, successToast } from "../../helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Loader from "../../helpers/Loader";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface ContactSupport {
  email?: string;
  phoneNo?: string;
  // Add other properties if needed
}


function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const [aboutUs, setAbout] = useState<string>("");
  const [cancellationPolicy, setCancellationPolicy] = useState<string>("");
  const [refundPolicy, setrefundPolicy] = useState<string>("");
  const [eula, setEula] = useState<string>("");

  const [inputdata, setInputdata] = useState<any>();
  const [dialCode, setPhoneCode] = useState("+1");
  console.log(dialCode, "code")
  const [ContactSupport, setContactSupport] = useState<ContactSupport>({});

  const [error1, setError1] = useState<boolean>(false);
  const [error2, setError2] = useState<boolean>(false);
  const [error3, setError3] = useState<boolean>(false);
  const [error4, setError4] = useState<boolean>(false);
  const [error5, setError5] = useState<boolean>(false);
  const [error6, setError6] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const [CountryCode, setCountryCode] = useState<string>("+91");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  console.log(dialCode, "dialCode");

  const [updateCms, { isLoading }] = useAddFaqMutation();
  const [getAllCms, { }] = useLazyGetAllFaqQuery();
  const [cmsData, setCmsData] = useState<any>(null);

  console.log("ContactSupport2", ContactSupport)


  const fetchData = async () => {
    try {
      const response = await getAllCms({}).unwrap();
      const cmsData = response?.data;
      console.log("Fetched CMS Data:", cmsData);
      formik.setValues({
        termsAndConditions: cmsData?.termsAndConditions || "",
        email: cmsData?.contactSupport?.email || "",
        phoneNo: cmsData?.contactSupport?.phoneNo || "",
        privacyPolicy: cmsData?.privacyPolicy || "",
        // Set the dialCode or default to dialCode state
        aboutUs: cmsData?.aboutUs || "",
        cancellationPolicy: cmsData?.cancellationPolicy || "",
        refundPolicy: cmsData?.refundPolicy || "",
        eula: cmsData?.eula || ""
      });

      console.log("Dial Code:", cmsData?.contactSupport?.dialCode || dialCode);
      setPrivacyPolicy(cmsData?.privacyPolicy || "");
      setTermsAndConditions(cmsData?.termsAndConditions || "");
      setAbout(cmsData?.aboutUs || "");
      setInputdata(cmsData?.contactSupport);
      setCancellationPolicy(cmsData?.cancellationPolicy || "");
      setrefundPolicy(cmsData?.refundPolicy || "")
      setEula(cmsData?.eula || "")
      // Update the dialCode state if available
      setPhoneCode(cmsData?.contactSupport?.dialCode || dialCode);
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  console.log("iujhdc", cmsData);
  const formik = useFormik({
    initialValues: {
      termsAndConditions: cmsData?.termsAndConditions || "",
      email: inputdata?.email || "",
      phoneNo: (inputdata?.phoneNo) || "",
      privacyPolicy: cmsData?.privacyPolicy || "",
      refundPolicy: cmsData?.refundPolicy || "",
      cancellationPolicy: cmsData?.cancellationPolicy || "",
      // dialCode: cmsData?.contactSupport?.dialCode ||dialCode,
      aboutUs: cmsData?.aboutUs || "",
      eula: cmsData?.aboutUs || ""
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      phoneNo: Yup.string().required("Phone is required").matches(
        /^[0-9]{9,15}$/,
        "Enter a valid phone number!"
      ),
      // privacyPolicy: Yup.string().min(9,"Privacy Policy is required").required("Privacy Policy is required"),


      // about: Yup.string().min(9).required("About is required"),
      // termsAndConditions: Yup.string().min(9).required("Terms & Conditions are required"),
    }),


    onSubmit: async (values) => {
      formik.setSubmitting(true);
      if (privacyPolicy.length < 9) {
        setError1(true);
        errorToast("Privacyt Policy is required")
        return;
      }
      else {
        setError1(false);
      }
      if (termsAndConditions.length < 9) {
        setError2(true);
        errorToast("Terms & Conditions is required")
        return;
      }
      else {

        setError2(false);
      }
      if (aboutUs.length < 9) {
        errorToast("About is required ")
        setError3(true);
        return;
      }
      else {
        setError3(false);
      }
      if (cancellationPolicy.length < 9) {
        errorToast("Cancellation Policy is required ")
        setError4(true);
        return;
      }
      else {
        setError4(false);
      }
      if (refundPolicy.length < 9) {
        errorToast("Refund Policy is required ")
        setError5(true);
        return;
      }
      else {
        setError5(false);
      }
      if (eula.length < 9) {
        errorToast("EULA is required ")
        setError6(true);
        return;
      }
      else {
        setError6(false);
      }
      let body = {
        termsAndConditions: termsAndConditions,
        contactSupport: {
          email: values.email,
          phoneNo: values.phoneNo,
          dialCode: dialCode,

        },


        privacyPolicy: privacyPolicy,
        eula: eula,
        aboutUs: aboutUs,
        cancellationPolicy: cancellationPolicy,
        refundPolicy: refundPolicy
      };
      console.log("bodddet", body);
      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateCms(encryptedBody).unwrap();

        if (response?.statusCode === 200) {
          successToast("Manage Cms Updated Successfully");
        }
      } catch (error: any) {
        console.error("this is an error");
      }
    },

  });

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue(
      "phoneNo",
      phone?.replace(country?.dialCode, "")
    );
    setPhoneCode(country?.dialCode.includes("+") ? "" : "+" + country?.dialCode);
  };





  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage CMS</h1>
          <Loader isLoad={isLoading} />
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              <Tab label="Privacy Policy" {...a11yProps(1)} />
              <Tab label="Term & Conditions" {...a11yProps(2)} />
              <Tab label="About" {...a11yProps(3)} />
              <Tab label="cancellation Policy" {...a11yProps(4)} />
              <Tab label="Refund Policy" {...a11yProps(5)} />
              <Tab label="EULA" {...a11yProps(6)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={(event) => formik.setFieldValue("email", event.target.value)}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.email && formik.errors.email ? String(formik.errors.email) : ""
                      }
                    />


                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      value={dialCode + formik.values.phoneNo}
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phoneNo && typeof formik.errors.phoneNo === 'string' ? (
                      <h6 className="err_msg">
                        {formik.errors.phoneNo}
                      </h6>
                    ) : (
                      ""
                    )}


                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                {error1 ? (
                  <h6 className="err_msg">
                    Privacy Policy is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                {error2 ? (
                  <h6 className="err_msg">
                    Terms & Condition is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            {/* about */}
            <CustomTabPanel value={value} index={3}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={aboutUs}
                  setContent={setAbout}
                />
                {error3 ? (
                  <h6 className="err_msg">
                    About  is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={cancellationPolicy}
                  setContent={setCancellationPolicy}
                />
                {error4 ? (
                  <h6 className="err_msg">
                    Cancellation Policy  is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={refundPolicy}
                  setContent={setrefundPolicy}
                />
                {error5 ? (
                  <h6 className="err_msg">
                    Refund Policy  is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={eula}
                  setContent={setEula}
                />
                {error6 ? (
                  <h6 className="err_msg">
                    Eula is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
