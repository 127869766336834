import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Button,
  Checkbox,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import {
  useLazyGetAllPaymentRequestQuery,
  useLazyGetTutorEarningByIdQuery,
  usePutupdateWithdrawStatusMutation,
  useLazyGetApprovedPaymentRequestQuery,
  useLazyGetRejectedPaymentRequestQuery,
} from "../../services/tutor";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "../../components/Pagination";
import Loader from "../../helpers/Loader";
import { errorToast, successToast } from "../../helpers/toast";
import { isValidInput } from "../../utils/validation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { socket } from "../../config/socket";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ManagePaymentRequest = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  console.log("991999", data);
  const { _id } = useParams<{ _id: string }>();
  const [fetchApi] = useLazyGetAllPaymentRequestQuery();
  const [approved] = useLazyGetApprovedPaymentRequestQuery();
  const [rejected] = useLazyGetRejectedPaymentRequestQuery();
  const [approvedRows, setApprovedRows] = useState<any>([]);
  const [rejectedRows, setRejectedRows] = useState<any>([]);
  const [updateWithdrawStatus, { isLoading: isUpdating }] =
    usePutupdateWithdrawStatusMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [rows, setRows] = useState<any[]>([]);
  console.log("----qwe37fd87q2e---", rows);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  console.log("00000", selectedRows);
  const [page, setPage] = useState(1);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalCount2, setTotalCount2] = useState<number>(0);
  const [totalCount3, setTotalCount3] = useState<number>(0);
  const totalPages = Math.ceil(totalCount / 10);
  const totalPages2 = Math.ceil(totalCount2 / 10);
  const totalPages3 = Math.ceil(totalCount3 / 10);
  const [fetchApiById] = useLazyGetTutorEarningByIdQuery();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setRows([]);
    setApprovedRows([]);
    setRejectedRows([]);
    setValue(newValue);
    setPage(1);
  };

  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm]);

  const handleWithdrawStatus = async (rowIds: string[], status: string) => {
    const rowsToUpdate = rows?.filter((r) => rowIds?.includes(r?._id));
    console.log("-099998", rows);
    const invalidRows = rowsToUpdate.filter(
      (r) => r.tutorWithdrawals[0]?.withdraw === 0
    );
    console.log("9999999977", rowIds);
    if (invalidRows.length > 0) {
      errorToast("One or more selected requests have 0 amount.");
      return;
    }

    try {
      const body = {
        ids: rowIds,
        status: status,
      };
      console.log("dlt22", body);
      const encryptedBody = {
        encryptedBody: generateEncryptedKeyBody(body) as CommonBody,
      };

      const response = await updateWithdrawStatus(encryptedBody).unwrap();

      if (response?.statusCode === 200) {
        successToast(
          response?.data?.message || "Withdraw status updated successfully"
        );
        fetchData(page, searchTerm);
        fetchApprovedData(page, searchTerm);
        fetchRejecteddData(page, searchTerm);
        setSelectedRows([]);
        setSelectAll(false);
      }
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const fetchData = async (page: number, search: string) => {
    setIsLoading(true);
    try {
      const response = await fetchApi({ page, search }).unwrap();
      if (response.statusCode === 200) {
        console.log("gnio", response?.data?.data);
        const formattedRows = response.data.data.map((item: any) => ({
          // _id: item._id,
          // withdrawId:item.tutorwithdrawl?._id,
          // totalWithdraw: item.totalwithdraw,
          // tutorWithdrawals: item.tutorwithdrawl,
          // tutorDetails: item.tutorId,
          // balance: item.balance,

          _id: item._id,
          withdrawId: item.tutorwithdrawl?._id,
          totalWithdraw: item.totalwithdraw,
          tutorWithdrawals: item.tutorwithdrawl,
          tutorDetails: item.tutorId,
          balance: item.balance,
        }));
        setRows(formattedRows);
        setTotalCount(response.data.total);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchApprovedData = async (page: number, search: string) => {
    setIsLoading(true);
    try {
      const response = await approved({ page, search }).unwrap();
      console.log("---9999923", response?.data?.data);

      if (response.statusCode === 200) {
        const formattedRows = response.data.data.map((item: any) => ({
          _id: item._id,
          withdrawId: item.tutorwithdrawl?._id,
          totalWithdraw: item.totalwithdraw,
          tutorWithdrawals: item.tutorwithdrawl,
          tutorDetails: item.tutorId,
          balance: item.balance,
        }));
        setApprovedRows(formattedRows);
        setTotalCount2(response?.data?.total);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRejecteddData = async (page: number, search: string) => {
    setIsLoading(true);
    try {
      const response = await rejected({ page, search }).unwrap();
      if (response.statusCode === 200) {
        const formattedRows = response.data.data.map((item: any) => ({
          _id: item._id,
          withdrawId: item.tutorwithdrawl?._id,
          totalWithdraw: item.totalwithdraw,
          tutorWithdrawals: item.tutorwithdrawl,
          tutorDetails: item.tutorId,
          balance: item.balance,
        }));
        setRejectedRows(formattedRows);
        setTotalCount3(response.data.total);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    setSelectedRows(event.target.checked ? rows.map((r) => r?.withdrawId) : []);
  };

  const handleRowCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: string
  ) => {
    if (event.target.checked) {
      setSelectedRows((prevSelected) => [...prevSelected, rowId]);
    } else {
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => id !== rowId)
      );
    }
  };

  const handleApprove = async () => {
    if (selectedRows.length === 0) {
      errorToast("Please select at least one row to approve.");
      return;
    }
    await handleWithdrawStatus(selectedRows, "accept");
  };

  const handleReject = async () => {
    if (selectedRows.length === 0) {
      errorToast("Please select at least one row to reject.");
      return;
    }
    await handleWithdrawStatus(selectedRows, "reject");
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0) {
      errorToast("Please select at least one row to delete.");
      return;
    }
    await handleWithdrawStatus(selectedRows, "delete");
  };

  const formatDateTime = (datetime: string) => {
    const date = new Date(datetime);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const getWithdrawMode = (withdrawMode: number) => {
    switch (withdrawMode) {
      case 1:
        return "Bank";
      case 2:
        return "Mobile money";
      default:
        return "-";
    }
  };

  useEffect(() => {
    if (value === 0) {
      fetchData(page, debouncedSearchTerm);
    } else if (value === 1) {
      fetchApprovedData(page, debouncedSearchTerm);
    } else if (value === 2) {
      fetchRejecteddData(page, debouncedSearchTerm);
    }
  }, [page, debouncedSearchTerm, value]);
  const handleWithdrawRequest = (it: any) => {
    console.log("00066", it);
    setData((prevData: any) => [it, ...prevData]);
  };

  useEffect(() => {
    const handleSocketEvent = (data: any) => {
      console.log("Socket_data_received:", data);
      fetchData(page, debouncedSearchTerm);

      if (data && data?.data) {
        const newItem = {
          _id: data?.data?._id,
          totalWithdraw: data?.data?.details.withdraw,
          tutorWithdrawals: [],
          tutorDetails: data?.data?.details.tutorId,
          balance: data?.data?.details?.balance,
        };

        if (data?.data?.details?.withdrawStatus === 1) {
          setRows((prevRows) => [newItem, ...prevRows]);
        } else if (data?.data?.details?.withdrawStatus === 2) {
          setApprovedRows((prevRows: any) => [newItem, ...prevRows]);
        } else if (data?.data?.details.withdrawStatus === 3) {
          setRejectedRows((prevRows: any) => [newItem, ...prevRows]);
        }
      }
    };

    socket?.on("withdrawRequest", handleSocketEvent);

    return () => {
      socket?.off("withdrawRequest", handleSocketEvent);
    };
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading || isUpdating} />
      <div className="main_layout">
        <div className="dashboard">
          <h2 className="mn_hdng">Payment Request</h2>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Pending" {...a11yProps(0)} />
              <Tab label="Approved" {...a11yProps(1)} />
              <Tab label="Rejected" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box className="cards_header">
              <SearchBar
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  {value === 0 && (
                    <>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#008000",
                          color: "#ffffff",
                          paddingLeft: 10,
                        }}
                        onClick={handleApprove}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "black",
                          color: "#ffffff",
                          marginLeft: "10px",
                        }}
                        onClick={handleReject}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "grey",
                      color: "#ffffff",
                      marginLeft: "10px",
                    }}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAllClick}
                        inputProps={{ "aria-label": "select all rows" }}
                      />
                    </TableCell>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Tutor Details</TableCell>
                    <TableCell>Phone No</TableCell>
                    <TableCell>Withdraw Amount</TableCell>
                    <TableCell>Withdraw Mode</TableCell>
                    <TableCell>Net Balance</TableCell>
                    <TableCell>Date & Time</TableCell>
                    <TableCell align="center">Request</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        No payment request found.
                      </TableCell>
                    </TableRow>
                  ) : (
                    rows.map((row, index) => (
                      <TableRow key={row._id}>
                        <TableCell>
                          <Checkbox
                            checked={selectedRows.includes(
                              row?.tutorWithdrawals?._id
                            )}
                            onChange={(event) =>
                              handleRowCheckboxClick(
                                event,
                                row?.tutorWithdrawals?._id
                              )
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          {(page - 1) * 10 + index + 1}
                        </TableCell>
                        <TableCell>
                          <div>{row?.tutorDetails?.name}</div>
                          <div>{row?.tutorDetails?.email || "-"}</div>
                        </TableCell>
                        <TableCell>{row.tutorDetails.phoneNo || "-"}</TableCell>
                        <TableCell align="center">
                          {row?.tutorWithdrawals?.withdraw > 0
                            ? row.tutorWithdrawals.withdraw
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {getWithdrawMode(row?.tutorWithdrawals?.withdrawMode)}
                        </TableCell>
                        <TableCell>{row?.balance?.toFixed(2) || "-"}</TableCell>
                        <TableCell>
                          {row?.tutorWithdrawals.createdAt ? (
                            <>
                              <div>
                                {new Date(
                                  row?.tutorWithdrawals?.createdAt
                                ).toLocaleDateString()}
                              </div>
                              <div>
                                {new Date(
                                  row?.tutorWithdrawals?.createdAt
                                ).toLocaleTimeString()}
                              </div>
                            </>
                          ) : (
                            "-"
                          )}
                        </TableCell>

                        <TableCell align="center">
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#008000",
                              color: "#ffffff",
                            }}
                            onClick={() =>
                              handleWithdrawStatus([row?.withdrawId], "accept")
                            }
                            disabled={
                              row?.tutorWithdrawals[0]?.withdrawStatus ===
                              "approved"
                            }
                          >
                            Accept
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "black",
                              color: "#ffffff",
                              marginLeft: "10px",
                            }}
                            onClick={() =>
                              handleWithdrawStatus([row?.withdrawId], "reject")
                            }
                            disabled={
                              row?.tutorWithdrawals[0]?.withdrawStatus ===
                              "rejected"
                            }
                          >
                            Reject
                          </Button>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              handleWithdrawStatus([row?.withdrawId], "delete")
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {rows.length > 0 && (
              <Pagination
                setPage={setPage}
                module={rows}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
              />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />

            <TableContainer className="table_container">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAllClick}
                        inputProps={{ "aria-label": "select all rows" }}
                      />
                    </TableCell> */}
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Tutor Details</TableCell>
                    <TableCell>Phone No</TableCell>
                    <TableCell>Withdraw Amount</TableCell>
                    <TableCell>Net Balance</TableCell>
                    <TableCell>Date & Time</TableCell>
                    <TableCell>Action</TableCell>
                    {/* <TableCell align="center">Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {approvedRows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No payment request found.
                      </TableCell>
                    </TableRow>
                  ) : (
                    approvedRows.map((row: any, index: any) => (
                      <TableRow key={row._id}>
                        {/* <TableCell>
          <Checkbox
            checked={selectedRows.includes(row._id)}
            onChange={(event) => handleRowCheckboxClick(event, row._id)}
          />
        </TableCell> */}
                        <TableCell align="center">
                          {(page - 1) * 10 + index + 1}
                        </TableCell>
                        <TableCell>
                          <div>{row?.tutorDetails?.name}</div>
                          <div>{row?.tutorDetails?.email || "-"}</div>
                        </TableCell>
                        <TableCell>
                          {row?.tutorDetails?.phoneNo || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.tutorWithdrawals?.withdraw > 0
                            ? row.tutorWithdrawals.withdraw
                            : "-"}
                        </TableCell>
                        <TableCell>{row?.balance?.toFixed(2) || "-"}</TableCell>
                        <TableCell>
                          {row?.tutorWithdrawals.createdAt ? (
                            <>
                              <div>
                                {new Date(
                                  row?.tutorWithdrawals?.createdAt
                                ).toLocaleDateString()}
                              </div>
                              <div>
                                {new Date(
                                  row?.tutorWithdrawals?.createdAt
                                ).toLocaleTimeString()}
                              </div>
                            </>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              handleWithdrawStatus([row?.withdrawId], "delete")
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>

                        {/* <TableCell align="center">
          <Button
            variant="contained"
            style={{ backgroundColor: "#008000", color: "#ffffff" }}
            onClick={() => handleWithdrawStatus([row._id], "accept")}
            disabled={row.tutorWithdrawals.withdrawStatus === "approved"}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "black", color: "#ffffff", marginLeft: "10px" }}
            onClick={() => handleWithdrawStatus([row._id], "reject")}
            disabled={row.tutorWithdrawals.withdrawStatus === "rejected"}
          >
            Reject
          </Button>
        </TableCell> */}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {approvedRows.length > 0 && (
              <Pagination
                setPage={setPage}
                module={approvedRows}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages2}
              />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            {/* Content for Rejected tab goes here */}
            <TableContainer className="table_container">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAllClick}
                        inputProps={{ "aria-label": "select all rows" }}
                      />
                    </TableCell> */}
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Tutor Details</TableCell>
                    <TableCell>Phone No</TableCell>
                    <TableCell>Withdraw Amount</TableCell>
                    <TableCell>Net Balance</TableCell>
                    <TableCell>Date & Time</TableCell>
                    <TableCell>Action</TableCell>
                    {/* <TableCell align="center">Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rejectedRows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No payment request found.
                      </TableCell>
                    </TableRow>
                  ) : (
                    rejectedRows.map((row: any, index: any) => (
                      <TableRow key={row._id}>
                        {/* <TableCell>
          <Checkbox
            checked={selectedRows.includes(row._id)}
            onChange={(event) => handleRowCheckboxClick(event, row._id)}
          />
        </TableCell> */}
                        <TableCell align="center">
                          {(page - 1) * 10 + index + 1}
                        </TableCell>
                        <TableCell>
                          <div>{row?.tutorDetails?.name}</div>
                          <div>{row?.tutorDetails?.email || "-"}</div>
                        </TableCell>
                        <TableCell>
                          {row?.tutorDetails?.phoneNo || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.tutorWithdrawals?.withdraw > 0
                            ? row.tutorWithdrawals.withdraw
                            : "-"}
                        </TableCell>
                        <TableCell>{row?.balance?.toFixed(2) || "-"}</TableCell>
                        <TableCell>
                          {row?.tutorWithdrawals.createdAt ? (
                            <>
                              <div>
                                {new Date(
                                  row?.tutorWithdrawals?.createdAt
                                ).toLocaleDateString()}
                              </div>
                              <div>
                                {new Date(
                                  row?.tutorWithdrawals?.createdAt
                                ).toLocaleTimeString()}
                              </div>
                            </>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              handleWithdrawStatus([row?.withdrawId], "delete")
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>

                        {/* <TableCell align="center">
          <Button
            variant="contained"
            style={{ backgroundColor: "#008000", color: "#ffffff" }}
            onClick={() => handleWithdrawStatus([row._id], "accept")}
            disabled={row.tutorWithdrawals.withdrawStatus === "approved"}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "black", color: "#ffffff", marginLeft: "10px" }}
            onClick={() => handleWithdrawStatus([row._id], "reject")}
            disabled={row.tutorWithdrawals.withdrawStatus === "rejected"}
          >
            Reject
          </Button>
        </TableCell> */}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {rejectedRows.length > 0 && (
              <Pagination
                setPage={setPage}
                module={rejectedRows}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages3}
              />
            )}
          </CustomTabPanel>
        </Card>
      </div>

      {/* {rows.length > 0 && (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )} */}
    </MainContainer>
  );
};

export default ManagePaymentRequest;
