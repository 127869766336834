import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { usePostAddNotificationMutation } from "../../services/notifications";
import { errorToast, successToast } from "../../helpers";


const AddNotification = () => {
  const navigate = useNavigate();
  const [receiver, setReceiver] = useState<string>("");
  const user = useAuth();
  const[role,setRole]=useState<any>("");
  console.log("role",role);
  const[addNotification]=usePostAddNotificationMutation();
  const[error1,setError1]=useState<boolean>(false);
  const handleReceiverChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string;
    setReceiver(selectedValue);
    
    if (selectedValue === "5" || selectedValue === "3"||selectedValue==="4") {
      setRole(selectedValue);
    }
    // } else {
 
    //   setRole("");
    // }
  };
  

  const formik = useFormik({
    initialValues: {
      notificationTitle: "",
      notificationMessage: "",
    role:role
    },
    validationSchema: Yup.object({
      notificationTitle: Yup.string().required("Title is required"),
      notificationMessage: Yup.string().required("Message is required")
    }),
    onSubmit: async (values) => {
      if(role !== "5" && role !== "3" && role !== "4") {
        // setError1(true);
        errorToast("Please Select Receiver");
        return;
      }
      
      formik.setSubmitting(true);
      try{
      let body = {
        title: values.notificationTitle || "",
        message: values.notificationMessage || "",
        role:role
      }

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await addNotification(encryptedBody).unwrap();
      if(response?.statusCode===200)
      {
        successToast("Notification Sent Successfully");
        navigate("/manage-notifications")
      }
    }
    catch(e:any){
    errorToast(e?.message);
    }
    }
  })

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Notification</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Title
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="notificationTitle"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      value={formik.values.notificationTitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.notificationTitle && formik.errors.notificationTitle}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Message
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="notificationMessage"
                      variant="outlined"
                      fullWidth
                      placeholder="Message"
                      value={formik.values.notificationMessage}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.notificationMessage && formik.errors.notificationMessage}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Select Receiver
                    </Typography>
                    {/* <FormControl sx={{ width: "100%" }}>
                      <NativeSelect
                        fullWidth
                        defaultValue={0}
                        variant="outlined"
                        inputProps={{
                          name: "action",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={0}>Select</option>
                        <option value={10}>IOS</option>
                        <option value={20}>Android</option>
                        <option value={30}>All</option>
                      </NativeSelect>
                    </FormControl> */}
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        // className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={receiver}
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleReceiverChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="5">All</MenuItem>
                        <MenuItem value="3">Tutor</MenuItem>
                        <MenuItem value="4">Parent</MenuItem>
                         
                      </Select>
                    </FormControl>
                    
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNotification;
