import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAddBankInfoMutation } from "../../services/bank";
import {  useLazyDeleteNotificationQuery, useLazyGetAllIndexNotificationsQuery, useLazyGetReviewerNotificationsQuery } from "../../services/notifications";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/modals/WarnModal";
import { errorToast, successToast } from "../../helpers/toast";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/Loader";
 
const ManageNotifications = () => {
  const navigate = useNavigate();
  const[getAllNotifications,{isLoading}]=useLazyGetAllIndexNotificationsQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  // const { _id } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
   const [deleteNotificationById] = useLazyDeleteNotificationQuery();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
const[rows,setRows]=useState<any>([]);
  function createData(name: string, title: string, message: string) {
    return { name, title, message };
  }
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  let totalPages = Math.ceil(totalCount / 10);

  // const rows = [createData("Abdul Hussian", "Title", "Message")];
  const fetchData = async (page: number, search: string) => {
    try {
      const response = await getAllNotifications({ page, search }).unwrap();
      if (response.statusCode === 200) {
        setRows(response?.data?.notification||[]);
        setTotalCount(response?.data?.totalNotification);
      } else {
        setRows([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteNotifications = async (userId: any) => {
    try {
      const response = await deleteNotificationById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Notification deleted successfully");
        fetchData(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm]);
  
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Notifications</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <Box className="cards_header">
            {/* <SearchBar /> */}
            {/* <Box className="cards_header_right">
              
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-notifications/add")}
              >
                Add Notification
              </Button>
            </Box> */}
               <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
              <Box className="cards_header_right">
 
          <Button
            className="btn btn_primary"
            onClick={() => navigate("/manage-notifications/add")}
          >
            Add Notification
          </Button>
        </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Sent To</TableCell>
                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {rows.length === 0 ? (
  <TableRow>
    <TableCell colSpan={6}>
      <Typography style={{marginLeft:400}}>No notification</Typography>
    </TableCell>
  </TableRow>
) : (
  rows.map((row: any, i: any) => (
    <TableRow key={row?._id}>
      <TableCell align="center">{i + 1}</TableCell>
      <TableCell>{new Date(row?.createdAt).toLocaleDateString()}</TableCell>
      <TableCell>{row?.title}</TableCell>
      <TableCell>{row?.message}</TableCell>
      <TableCell>
        {row?.role === 5
          ? "All"
          : row?.role === 3
          ? "Tutor"
          : row?.role === 4
          ? "Parent"
          : "-"}
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={() => {
            setOpen(true);
            setSelectedId(row?._id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ))
)}

              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteNotifications(selectedId)}
        name="Notification"
      />
    </MainContainer>
  
  );
};

export default ManageNotifications;
