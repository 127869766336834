import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLazyDeleteNotificationQuery } from "../../services/notifications";
import { useLazyGetReviewerNotificationsQuery } from "../../services/notifications";
import { errorToast, successToast } from "../../helpers";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/modals/WarnModal";
const RecievedNotifications = () => {
   
 
  const label = { inputProps: { "aria-label": "Switch demo" } };
  
  const navigate = useNavigate();
  const[getAllNotifications,{isLoading}]=useLazyGetReviewerNotificationsQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  // const { _id } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
   const [deleteNotificationById] = useLazyDeleteNotificationQuery();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
const[rows,setRows]=useState<any>([]);
  function createData(name: string, title: string, message: string) {
    return { name, title, message };
  }
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  let totalPages = Math.ceil(totalCount / 10);

  // const rows = [createData("Abdul Hussian", "Title", "Message")];
  const fetchData = async (page: number, search: string) => {
    try {
      const response = await getAllNotifications({ page, search }).unwrap();
      if (response.statusCode === 200) {
        setRows(response?.data?.notification||[]);
        setTotalCount(response?.data?.totalNotification);
      } else {
        setRows([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
 
  const handleDeleteNotifications = async (userId: any) => {
    try {
      const response = await deleteNotificationById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Notification deleted successfully");
        fetchData(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  const formatDate = (dateString: string | number | Date) => {
    const options:any = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  
  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm]);
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Received Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            {/* <SearchBar /> */}
          </Box>
          <TableContainer className="table_container">
      <Box className="heading"></Box>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">S.No</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Time & Date</TableCell>
            <TableCell>Action</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows.map((row:any, i:any) => (
              <TableRow key={i}>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.message}</TableCell>
                <TableCell>{formatDate(row.createdAt)}</TableCell>
                <TableCell>
                  <Box className="table_actions">
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setSelectedId(row?._id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <Typography>No notification</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteNotifications(selectedId)}
        name="Notification"
      />
    </MainContainer>
  );
};

export default RecievedNotifications;
